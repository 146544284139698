.v-popup{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	box-sizing: border-box;
	padding: 1em;

	transition: 300ms;

	&.--is-open{
		display: flex;
		opacity: 1;
		visibility: visible;
	}

	&__bg{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: #00000078;


	}
	&__dialog{
		min-width: 400px;
		min-height: 300px;
		background-color: #fff;
		position: relative;
		z-index: 1;
		border-radius: 6px;
		padding: 3em 5em;

		box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);

		box-sizing: border-box;

		@include size(s){
			padding: 2em;
		}

		&-close{
			cursor: pointer;
			position: absolute;
			width: 20px;
			height: 20px;
			right: 1em;
			top: 1em;

			background: url(/img/close.svg) no-repeat center;
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg height='329pt' viewBox='0 0 329.26933 329' width='329pt' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0'/%3E%3C/svg%3E");

			background-size: contain;
		}
	}

	&.--no-padding{
		.v-popup__dialog{
			padding: 0;
		}
	}


}

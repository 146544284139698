.checkbox{
    --icon-size:20px;
    display: flex;
    align-items: center;
    position: relative;
    line-height: 1em;
    cursor: pointer;
    align-content: center;
    align-items: center;
    input{
        appearance: none;
        display: block;
        width: var(--icon-size);
        height: var(--icon-size);
        border: 1px solid #000;
        border-radius: 3px;
        margin-right: 1em;
        outline: none;
        cursor: pointer;
        position: relative;
        &:checked{
            background: #d946af;
            border: 1px solid #d946af;
             &::before{
                content: '\2713';
                position: absolute;
                font-size: 13px;
                font-weight: bold;
                top: calc(50% - 1px);
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }
    }
    span{

    }
}
.btn{

	--font-color:#000;
	--background-color:transparent;
	--border-color:var(--font-color);

	display: block;
    padding: 19px 2em 18px;
    line-height: 1em;
    border: 1px solid var(--border-color);
    font-size: 17px;
    border-radius: 4px;
    color: var(--font-color);
    font-weight: 600;
    cursor: pointer;
    background-color: var(--background-color);
	outline: none;
	box-sizing: border-box;
	transition: 200ms;
	justify-content: center;

	display: inline-flex;


	@include size(l){
		padding: 16px 30px 15px;
		font-size: 16px;
	}

	&.--link{
		padding: 0;
		border: 0;
		background: transparent;
	}

	&.--icon{
		display: inline-flex;
		align-items: center;
		.icon{
			margin-left: 10px;
			--size: 20px;
			height: 1em;
			position: relative;
			top: 1px;
		
		}
	}


    @each $colorName, $colorValue in $color {
		&.--#{$colorName}{
			--font-color: #fff;
			--background-color:#{$colorValue};
			--border-color:var(--background-color);

			&.--icon{
				color: #{$colorValue};
				.icon{
					fill:#{$colorValue}
				}
			}
			&:hover{
				filter: saturate(1.1) contrast(1.2);
			}
		
		};
	}

}
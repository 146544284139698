
@mixin css3-prefix($property, $value) {
	-webkit-#{$property}: #{$value};
	-ms-#{$property}: #{$value};
	#{$property}: #{$value};
}

@mixin size($breakpoint) {
	@if map-has-key($windowSizes, $breakpoint) {

		@media screen and (max-width: #{map-get($windowSizes, $breakpoint)}) {
			@content;
		}
	}

	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
	}
}
@mixin screen($size) {
	@media screen and (max-width: #{$size}px) {
		@content;
	}
}

$color: (
	font: #181C22,
	peach: #C6A67C,
	dark: #97A4B6,
	smoke: #F7F8F9,
	gray: #C3C9D3,
	night: #3C3E41,
);
$params: (
	container-min-width:375px,
	container-max-width:1290px,
	container-max-width-l:1425px,
	container-margin:0px auto,
	container-padding:0 1em,
	border-radius: 5px,
	animation:200ms,
);

$font: (
    family: "'Museo Sans Cyrl', Sans-Serif",
    size:18px,
);

$font-size-tags: (
    body:var(--font-size),
    p:1rem,
);
$headersSizes: (
	h1: 50px,
    h2: 36px, 
    h3: 30px, 
    h4: 24px,
    h5: 18px,
);

@each $tagName, $tagValue in $headersSizes {
	
	#{$tagName}, .#{ $tagName}{
		display: block;
		font-weight: 600;
		margin: 0;
		padding: 0;
		line-height: 1.4em;
		font-size: #{$tagValue};
		margin-bottom: 30px;
	}
}

$grid-length: 10, 8, 6, 5, 4, 3, 2, 1;
$mgb-mgt-length:0,1,2,3,4,5,6;
$container-sizes: 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100;
$windowSizes: (
	l:1280px,
	m:1024px,
	s:910px,
	xs:640px,
);


:root {
    
    @each $name, $value in $params {
        --#{$name}: #{$value};
    }

    @each $name, $value in $font {
      --font-#{$name}:  #{$value};
    }
    @each $name, $value in $font-size-tags {
        --font-size-#{$name}:  #{$value};
	}
	@each $name, $c in $color {
		--color-#{$name}: #{$c};
	}


}



button, input, select, textarea{
    font-family: var(--font-family);	
	font-weight: 400;
	outline-color: var(--color-peach);
}

.nowrap{
	white-space: nowrap;
	text-overflow: ellipsis;
	-ms-text-overflow: ellipsis;
	overflow: hidden;
}
.cf:before,
.cf:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.cf:after {
	clear: both;
}

.cf {
	*zoom: 1;
}
.clear{	clear:both;}

.table{display: table;}
.table-cell{display: table-cell;vertical-align: middle;box-sizing: border-box;}
.table-row{display: table-row; vertical-align: middle; box-sizing: border-box;}
.table-cell.right-cell{text-align:right;}

$alAlign: (
	'al-left': (
		"text-align":"left!important",
		"justify-content":"flex-start",
	),
	'al-center': (
		"text-align":"center!important",
		"justify-content":"center",
	),
	'al-right': (
		"text-align":"right!important",
		"justify-content":"flex-end",
	),

);
$vlAlign: (
	'vl-top': "top!important",
	'vl-middle': "middle!important",
	'vl-bottom': "bottom!important",
	'vl-inline': "baseline!important",
);

@each $class, $val in $alAlign {
	.#{$class}{
		@each $param, $value in $val {
			#{$param}:#{$value};
		}

	}
}
@each $class, $val in $vlAlign {
	.#{$class}{vertical-align:#{$val};}
}

.centered{margin-left: auto;margin-right: auto;}
.db{display: block;}
.dtc{display: table-cell;}
.dib{display: inline-block;}
.dn{display: none;}
.hidden, .vh{visibility: hidden;opacity: 0;width: 0;height: 0;position: absolute;overflow: hidden;}
.visible, .vs{
	visibility: hidden;
	opacity: 1;
	width: auto;
	height: auto;
	position: relative;
	overflow: visible;
}
.hide{opacity: 0;visibility: hidden;}
.show{opacity: 1;visibility: visible;}

.w33{
	width:calc(100% / 3);
}

.container {
	max-width: var(--container-max-width);
	min-width: var(--container-min-width);
	margin: var(--container-margin);
	padding: var(--container-padding);
	box-sizing: border-box;
}



@each $size in $container-sizes {
	.w#{$size}{width:#{$size + '%'}}
	.h#{$size}{height:#{$size + '%'}}

}

@each $number in $mgb-mgt-length {
	.mgb#{$number}{margin-bottom:#{$number + 'em'};--grid-item-margin-bottom: #{$number + 'em'};}
	.mgt#{$number}{margin-top:#{$number + 'em'}}
}

/* Отступы */

@each $className, $ident in (
		'0': 0,
		'10': '1em 0 1em 0',
		'01': '0 1em 0 1em',
		'1': '1em 1em 1em 1em',
		'20': '2em 0 2em 0',
		'02': '0 2em 0 2em',
		'2': '2em 2em 2em 2em',
		'30': '3em 0 3em 0',
		'03': '0 3em 0 3em',
		'3': '3em 3em 3em 3em',
) {

	.p#{$className}{padding:#{$ident}}
	.m#{$className}{margin:#{$ident}}


	@each $SizeName, $WindowSize in $windowSizes {
		@media only screen and (max-width: $WindowSize) {
			.#{$SizeName}-p#{$className}{padding:#{$ident}!important;}
			.#{$SizeName}-m#{$className}{margin:#{$ident}!important;}
		}
	}

}

@each $SizeName, $WindowSize in $windowSizes {
	@media only screen and (max-width: $WindowSize) {
		.scroll-x,
		.#{$SizeName}-scroll-x{
			width: auto;
			overflow-x: auto;
			overflow-y: hidden;
			-webkit-overflow-scrolling: touch;
			position: relative;
			display: flex;
			flex-wrap: nowrap!important;
			margin-left: 0!important;
			margin-right: -1em!important;

			&::-webkit-scrollbar {
				height: 8px;
				cursor: pointer;
				background-color: var(--color-bg-light);
				border-radius: 5px;
			}

			&::-webkit-scrollbar-thumb {
				height: 8px;
				background-color:  var(--color-font);
				border-radius: 5px;
				cursor: pointer;
			}

			.grid-item,
			.scroll-item{
				min-width: 230px;
				padding: 0;
				margin-right: var(--grid-space);
			}
		}
	}

}

.grid-item{
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	position: relative;
	width:calc(100% / var(--grid-size));
	align-content: start;
	padding:0 calc(var(--grid-space) / 2);
	margin-bottom: 1em;

}

@each $grid in $grid-length {
	.grid-#{$grid}{
		--grid-space:1em;
		width: auto;
		margin: 0 calc(calc(var(--grid-space) / 2) - calc(calc(var(--grid-space) / 2) * 2));

		@if $grid == 1 {
			margin-left: 0;
			margin-right: 0;
		}
		@include css3-prefix(display, flex);
		@include css3-prefix(flex-wrap, wrap);
	}

}

@each $grid in $grid-length {
	.grid-#{$grid}{
		--grid-size:#{$grid};
	}
}
@each $SizeName, $WindowSize in $windowSizes {

	@media only screen and (max-width: $WindowSize) {


		@each $grid in $grid-length {
			.#{$SizeName}-grid-#{$grid}{
				--grid-size:#{$grid};

				@if $grid == 1 {
					margin-left: 0;
					margin-right: 0;
					.grid-item{
						padding-left: 0;
						padding-right: 0;
					}
				}
			}


		}

		@each $class, $val in $alAlign {
			.#{$SizeName}-#{$class}{
				@each $param, $value in $val {
					#{$param}:#{$value};
				}

			}
		}


		@each $class, $val in $vlAlign {
			.#{$SizeName}-#{$class}{vertical-align:#{$val};}
		}


		@each $size in $container-sizes {
			.grid-item.m-w#{$size}{width:#{$size + '%'}}
			.#{$SizeName}-w#{$size}{width:#{$size + '%'}}
			.#{$SizeName}-h#{$size}{height:#{$size + '%'}}
		}


		.#{$SizeName}-dn{display: none!important;}
		.#{$SizeName}-db{display: block!important;}
		.#{$SizeName}-dib{display: inline-block!important;}
		.#{$SizeName}-al-left{text-align:left!important;
			justify-content: flex-start;}
		.#{$SizeName}-al-center{
			text-align:center!important;
			justify-content: center;
		}
		.#{$SizeName}-al-right{text-align:right!important;
			justify-content: flex-end;}
		.#{$SizeName}-vl-top{vertical-align:top!important;}
		.#{$SizeName}-vl-middle{vertical-align:middle!important;}
		.#{$SizeName}-vl-bottom{vertical-align:bottom!important;}

		@each $number in $mgb-mgt-length {
			.#{$SizeName}-mgb#{$number}{margin-bottom:#{$number + 'em'}!important}
			.#{$SizeName}-mgt#{$number}{margin-top:#{$number + 'em'}!important}
		}
	}
}
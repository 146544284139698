
input[type=range] {
	--range-size:18px;
	width: 100%;
	-webkit-appearance: none;
	background: transparent;
	&:focus {
		outline: none;
	}

	&:disabled {
		opacity: 0.5;
	}

	&::-webkit-slider-runnable-track {
		height: 1px;
		background: transparent;
	}
	
	&::-webkit-slider-thumb {
		width: var(--range-size);
		-webkit-appearance: none;
		height: var(--range-size);
		cursor: pointer;
		background: #fff;
		position: relative;
		transform: translateY(-50%);
		border-radius: 50%;
		box-shadow: inset 0px 0px 0px 5px #d946af, 1px 1px 2px 0px #0000002e;
	}

	&::-webkit-progress-value {
		background-color: orange;
	}

	&::-moz-range-progress {
		background-color: #43e5f7; 
	}
	&::-moz-range-track {  
		background-color: #9a905d;
	}
	&::-ms-fill-lower {
		background-color: #43e5f7; 
	}
	&::-ms-fill-upper {  
		background-color: #9a905d;
	}




}

.dropdown{
    position: relative;
    .dropdown-current{
        display: block;
        position: relative;
        cursor: pointer;
        padding: 1rem;

        .dropdown-value{

        }
        .dropdown-icon{
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            transform-origin: center;
            svg{
                width: 100%;
                height: 100%;
            }
        }

    }
    .dropdown-items{
        opacity: 0;
        visibility: hidden;
        position: absolute;
        transition: 200ms;
        width: 100%;
        transform: translateY(-10px);
        background: #fff;
        border-top: 1px solid #ddd;
    }
    .dropdown-item{
        display: block;
        position: relative;
        cursor: pointer;
        padding: 1rem;
        &:hover{
            background: whitesmoke;
        }
    }

    &.--is-open{

        .dropdown-items{
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
        .dropdown-icon{
            transform: translateY(-50%)  rotate(180deg);
        }
    }
}

@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-100.woff2') format('woff2'),
        url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-100.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-300.woff2') format('woff2'),
        url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-500.woff2') format('woff2'),
        url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-700.woff2') format('woff2'),
        url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-700.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Museo Sans Cyrl';
    src: url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-900.woff2') format('woff2'),
        url('/dist/fonts/MuseoSansCyrl/subset-MuseoSansCyrl-900.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
:root{
    --side-padding:calc(calc(var(--container-max-width-l) - var(--container-max-width))  / 2);

	@include size(l)
	{
		--container-max-width: 100%;
	}
}

body{
    font-family: var(--font-family);
    font-size: var(--font-size);
    line-height: 1.4em;
}

h1,.h1{
	@include size(l){
		font-size: 42px;
	}
}

.h2, h2{
	 @include size(l) {
		 font-size: 33px;
	 }

	 @include size(s){
		 font-size: 26px;
	 }
 }

.h3, h3{
	@include size(m) {
		font-size: 23px;
	}

	@include size(s){
		font-size: 20px;
	}
}

#app{
	&.--has-bg{
	//	background: url(/dist/img/has-bg.jpg) no-repeat center top;
	}
}

.example{
    position: relative;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
        
    }
    &.--main{
       &::before{
            background: url(/dist/img/examples/main.jpg);
       }
    }

    #app{
        position: relative;
        opacity: 0.5;
        z-index: 2;
    }
}

.container{
    &.--width-l{
        max-width:var(--container-max-width-l);

		@include size(l){
			max-width:var(--container-max-width);
		}
        
    }
}

a{
    color: #000;
    text-decoration: none;
    &:hover{
        color:var(--color-dark)
    }
}

.icon{
    --size:30px;
    width: var(--size);
    height: var(--size);
    display: inline-flex;
    max-width: 100%;
    max-height: 100%;
}

.breadcrumbs{
	display: flex;
	&__item{
		display: inline-flex;
	}
	span.breadcrumbs__item{
		color: #8a8a8a;
	}
	&__sep{
		display: inline-flex;
		padding: 0 5px 0 6px;
	}
}

.header{
	border-bottom: 1px solid #ececec;
	background: whitesmoke;
    .header__top{

        .container{
            display: flex;
            align-items: center;

            .nav{
                .nav__item{
                    padding: 14px 0;
                    line-height: 1em;
                    margin-right: 2em;
					font-weight: 700;

					@include size(l)
					{
						margin-right: 20px;
					}
                }
                &.--small{
                    margin-left: auto;
                    .nav__item{
                        margin-left: 1.5em;
                        margin-right: 0;
                        padding: 12px 0;
						font-weight: 500;

                    }
                }
                
            } 
        }

    }

	&__nav-btn{
		display: none;
		@include size(m){
			display: inline-flex;
			width: 40px;
			height: 40px;
			padding: 9px;
			text-align: center;
			justify-content: center;
			border: 0px solid #97a4b6;
			margin-right: 1em;
			border-radius: 4px;
			background: #dee3e8;
		}

		&-lead{
			margin-left: auto;
			margin-right: 0;
		}
	}

    .header__main{
        .container{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 1em;
            padding-bottom: 1em;
        }
        .header__phone{
            margin-right: 2em;

        }

    }
    .header__phone{
        font-size: 25px;
        font-weight: 600;
		@include size(m){
			font-size: 20px;
		}
		@include size(s){
			display: none;
		}
    }


	@include size(s){
		.logo{
			margin-left: auto;
		}

		.btn__modal{
			display: none;
		}
	}


}

.logo{
    display: flex;
    align-items: center;
    color: #1D2025;
    &-image{
        margin-right: 10px;
        --size: 50px;
        width: 50px;
    }
    &-text{
        font-size: 30px;
        font-weight: 900;
        text-transform: lowercase;
        line-height: 23px;
        letter-spacing: -1.2px;
    }
	&:hover{
		color: var(--color-dark);
		svg{
			fill: var(--color-dark);
		}
	}
}

.logo-box{
    margin-right: auto;
    display: flex;
    align-items: center;

	@include size(s){
		margin-right: 0;
		margin-left: auto;
	}

    .logo{
        padding-right: 35px;
        border-right: 1px solid #E5E5E5;
        margin-right:22px;
    }
    .logo-description{
        font-size: 16px;
        color: #393F49;
        max-width: 300px;
        line-height: 1.2em;
        letter-spacing: -0.5px;

		@include size(m){
			font-size: 14px;
			max-width: 270px;
		}
    }

	@include size(m){
		.logo{
			padding-right: 0px;
			border-right: 0px solid #E5E5E5;
			margin-right:0px;
		}
		.logo-description{
			display: none;
		}
	}
}


.nav{

    .nav__item{
        font-size: 20px;
        display: inline-flex;
		color: var(--color-night);
		&:hover
		{
			color: #000;
		}
		@include size(l)
		{
			font-size: 18px;
		}
    }

    &.--small{
        .nav__item{
            font-weight: 300;
            font-size: 17px;
			@include size(l)
			{
				font-size: 16px;
			}
        }
    }
}

.footer{
    border-top: 1px solid var(--color-gray);
    .container{
        padding-top: 50px;
        padding-bottom: 30px;
    }


    .table{
        .table-cell{
            padding-right: 50px;

			@include size(s){
				padding: 0;
			}

            &:last-child{
                padding-right: 0;
            }
        }
    }
	.logo-box{
		@include size(s){
			justify-content: center;
		}
	}
	.contacts__btn{
		@include size(s){
			display: flex;
			justify-content: center;
		}

	}
    .nav{
        &__item{
            font-size: 18px;
            margin-bottom: 12px;
            display: block;
            width: 100%;
        }

        &.--small{
            margin-top: 25px;

            .nav__item{
                font-size: 15px;
                margin-bottom: 8px;
            }
        }
    }

    &__copy{
        font-weight: 300;
        font-size: 13px;
        line-height: 18px;
        /* or 138% */
        display: block;
        
        color: #838383;
    }

    &__links{
        display: flex;
        font-size: 15px;
		@include size(s)
		{
			flex-wrap: wrap;
		}
        a{
            margin-right: 10px;
			@include size(s){
				display: block;
				width: 100%;
			}
        }
    }
}

.contacts{
    font-weight: 300;
    line-height: 1.5;
    font-size: 17px;
    .bold{
        font-weight: 700;
    }
    .semi-bold{
        font-weight: 600;
    }


	&__padding{
		padding-left: 50px;
	}


	&__table{
		display: flex;
		@include size(s){
			flex-wrap: wrap;
		}
		&-left{
			width: 400px;
			padding-right: 40px;
			@include size(s){
				width: 100%;
				padding-right: 0;
				margin-bottom: 50px;
			}
		}

		&-right{
			width: calc(100% - 400px);
			@include size(s){
				width: 100%;
				height: 320px;
			}
		}


	}
	&__list{

	}

	&__box{
		display: block;
		margin-bottom: 40px;
	}
	&__title{
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		position: relative;
		align-items: center;
		display: flex;
		margin-bottom: 15px;

		svg.icon {
			--size:22px;
			position: absolute;
			left: 0;
		}
		span{

		}
	}
	&__value{
		font-weight: 300;
		font-size: 17px;
		b{
			font-size: 22px;
			font-weight: 400;
		}
	}
}


.home-content{

    h1{
        font-weight: 400;
        line-height: 1;
        margin-bottom: 26px;
        margin-top: 36px;
        span{
            font-weight: 800;
            color:var(--color-dark);
            display: block;
        }
		@include size(m){
			font-size: 38px;
		}

		@include size(s){
			font-size: 29px;
		}
    }

	.container{
		height: 400px;
		padding-top: 100px;

		@include size(s){
			height: 180px;
			 padding-top: 0px;
			text-align: center;
		}
	}

    &__items{
        --grid-space: 0;

    }

    &__item{
        position: relative;
		@include size(s){
			max-height: 300px;
		}

        &-box{
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            transition: 200ms;
            background: linear-gradient(0deg, #ffffff00, #0000008f);
            padding: 1.5em 3.5em;
  
            padding-bottom: 50%;

			@include size(l){
				padding-left: 2em;
				padding-right: 2em;
			}
        }

        &:hover{
            .home-content__item-box{
                background: linear-gradient(0deg, #ffffff00, #00000052);
            }
        }

        &-img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
			object-fit: cover;
        }

        &-desct{
            display: block;
            color: #e2e2e2;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 300;
			@include size(l){
				line-height: 1.2em;
				font-size: 14px;
			}
        }

        &-title{
            font-size: 33px;
            font-weight: 800;
            color: #fff;
			@include size(l){
				font-size: 28px;
			}
        }

    }
}


.proofs{

    &__list{
        display: flex;
        justify-content: space-between;
		flex-wrap: wrap;
		@include size(m){
			flex-wrap: wrap;
		}
    }
    &__separator{
        width: 2px;
        height: 50px;
        background: var(--color-smoke);
		display: none;
    }
    &__item{

        line-height: 1.1em;

		max-width: 50%;
		width: 50%;
		margin-bottom: 50px;
		padding-right: 1em;
		box-sizing: border-box;

		@include size(m){
			max-width: 100%;
			width: 100%;
		}
		&:nth-child(3n),
		&:nth-child(4n)
		{
			margin-bottom: 0;
		}

		@include size(s){
			&:nth-child(3n)
			{
				margin-bottom: 50px;
			}
		}

        &-number{
            font-size: 48px;
            color: var(--color-night);
            font-weight: 800;
            display: block;
            margin-bottom: 20px;
			@include size(l){
				font-size: 40px;
			}

			@include size(m){
				font-size: 28px;
				line-height: 28px;
				margin-bottom: 5px;
			}


        }
        &-text{
            word-wrap: pre;

			@include size(s){
				padding-right: 10px;
			}
        }
    }
}

.about{
    .container{
        padding-bottom: 100px;
		@include size(s){
			padding-bottom: 2em;
		}
        p{
            max-width: 65%;

            &.about-light{
                font-weight: 300;
            }

			@include size(m){
				max-width: 100%;
			}
        }
        
    }
}
p{
    line-height: 1.5em;
    &.--bigger{
        font-size: 22px;
		@include size(l){
			font-size: 20px;
		}
    }
}

.header-bg{
    background: url(/uploads/images/header-bg.jpg) no-repeat center top;

	@include size(s){
		background-size: cover;
		background-position: calc(50% - 50px) center;
	}



}

.about-bg{
    background: url(/dist/img/about-bg.jpg) no-repeat center calc(100% + 5px);
}


.service{
    background: url(/dist/img/map.jpg) no-repeat center right;
    .container{

    }

    &__plus{
        border-bottom: 1px solid var(--color-smoke);
        margin-bottom: 1em;
        padding-bottom: 3rem;
		@include size(l){
			font-size: 17px;
			padding-bottom: 1rem;
		}

		@include size(m){
			padding-bottom: 1rem;
		}

        &-item{
            margin-bottom: 0;
            align-items: center;

			@include size(m){
				font-size: 20px;
				padding-bottom: 1rem;
				margin-bottom: 20px;

				&:nth-child(3n),
				&:nth-child(4n){
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}


            svg{
                margin-right: 1em;
				@include size(l){
					--size: 25px;
					margin-right: 10px;
				}
            }
            span{

            }
        }
    }

    &__list{
        width: 100%;
        &-item{
            display: flex;
            border-bottom: 1px solid var(--color-smoke);
            padding-top: 30px;
            padding-bottom: 30px;

            &:nth-child(1){
                .service__list-icon{

                    .icon{
                        height: 58px;
                        width: 75px;
                    }
                    
                }
            }
            &:nth-child(2){
                .service__list-icon{

                    .icon{
                        width: 65px;
                        height: 100px;
                    }
                    
                }
            }
            &:nth-child(3){
                border-bottom: 0px;
                .service__list-icon{
                    
                    
                }
            }
        }


        &-icon{
            width: 40%;

			@include size(l){
				width: 35%;
			}

			@include size(m){
				width: 200px;
			}


			@include size(s){
				width: 100px;
				height: 100px;
			}

			.icon{
                height: 100%;
                width: 65px;
                margin: 0 auto;
                display: block;
            }
        }
        &-descr{
            width: 60%;
            font-weight: 300;

			@include size(l){
				width: 65%;
			}

			@include size(m){
				width: calc(100% - 200px);
			}


			@include size(s){
				width: calc(100% - 100px);
			}
            a{

            }
        }

        &-text{
            display: block;
            margin-bottom: 25px;
        }

        &-title{
            margin-bottom: 15px;
            line-height: 1.2em;
        }
    }


    &__box{


        &-left{
            
        }
        &-right{
          
            align-content: center;
            justify-content: flex-end;

			@include size(s){
				justify-content: center;
				text-align: center;
			}

            &-text{
                max-width: 80%;
				@include size(s){
					max-width: 100%;
				}
            }
        }

        
    }
    
}

.--main{
	.header{
		background: transparent!important;
		border: 0!important;
	}
	.proofs{
		background: var(--color-smoke);
		padding-top: 70px;
		padding-bottom: 10px;
	}
	.service{
		background: #3C3E41;
		.container{
			border: 0;

		}
		.service__plus{
			padding: 1em 0;
			border-bottom: 0;

			&-item{
				color: #fff;
			}
		}
	}
}

.form-container{
    .container{
        background: var(--color-smoke);


    }



    .form-box{

        display: flex;
        align-content: center;
        padding: 70px;

		@include size(s){
			padding: 2em 0;
		}
        
        .h2{
            margin-bottom: 15px ;
			@include size(l){
				font-size: 30px;
			}
        }
        .sub-h2{
            font-size: 22px;
            margin-bottom: 30px;

			@include size(l){
				font-size: 19px;
			}
        }

		@include size(s){
			flex-wrap: wrap;
		}
        &__left{
            width: 60%;

			@include size(s){
				width: 100%;
				text-align: center;
				margin-bottom: 50px;
			}

            small{
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: -0.02em;
                color: #838383;
                max-width: 600px;
                display: block;
            }
        }
        &__right{
            margin-left: auto;
            width: 30%;
            display: flex;
            justify-content: flex-end;

			@include size(s){
				width: 100%;
				justify-content: center;
			}
        }   
    }
}

.form-state{
    width:  300px;
	@include size(s){
		width: 100%;
	}
    &__input{
        font-size: 1em;
        padding: 1em;
        border: 1px solid var(--color-dark);
        width: 100%;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 10px;
		box-shadow: none !important;
    }
    &__small{
        font-size: 11px;
        color: #838383;
        line-height: 1.5em;
        display: block;
        padding: 0 2em;
        box-sizing: border-box;
        margin-bottom: 30px;
    }


}


.profit{
    &__list{

    }
    &__item{
        display: flex;
        align-items: center;
        margin-bottom: 3em;

		@include size(s){
			margin-bottom: 2em;
		}

		@include size(s){
			flex-wrap: wrap;
		}
		&:last-child{
			margin-bottom: 0;
		}
        &-icon{
            width: 70px;
            height: 70px;
			@include size(s){
				width: 50px;
				height: 50px;
				margin-bottom: 10px;
			}
        }
        &-text{
            width: calc(100% - 70px);
            padding-left: 1em;
            box-sizing: border-box;
			@include size(s){
				width: 100%;
				padding-left: 0;
			}
        }
    }
}

.products{
    &__list{
        --grid-space:0;
        .grid-item{
            
            &:nth-child(2){
                .products__item  {
                    --padding:30px;
                }
               
            }
        }
    }

    &__item{
        --padding:var(--side-padding);
		display: block;
		width: 100%;

		@include size(l){
			--padding:20px;
		}

        &-image{
            display: block;
            max-width: 100%;
            margin-bottom: 20px;
        }
        &-title{
            font-weight: 600;
            font-size: 28px;
            color: var(--color-night);
            display: block;
            margin-bottom: 10px;
            padding: 0 var(--padding);

			@include size(l){
				font-size: 24px;
			}

			@include size(s){
				font-size: 20px;
			}
        }
        &-price{
            padding: 0 var(--padding);
            font-weight: 300;
            font-size: 20px;
            color: var(--color-peach);
            display: block;

			@include size(l){
				font-weight: 400;
				font-size: 21px;
			}
        }
    }
}

.prices{
    &-bg{
        background: var(--color-smoke);
        box-sizing: border-box;
        padding: 2em 0;
    }

    &__list{

    }
    &__item{
        background: #fff;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        color: #181C22;
		height: 100%;
		@include screen(1300){
			padding: 24px;
		}

		@include size(l){
			width: 232px;
		}

        &-box{

        }
        &-img{
            margin: 0 auto 1em;
            display: block;
        }
        &-descr{
            font-weight: 500;
            font-size: 13px;
            color: #838383;
        }
        &-title{
            font-weight: 700;
            font-size: 20px;
			@include screen(1300){
				font-size: 18px;
			}
        }
        &-numbers{
            font-weight: 500;
            font-size: 18px;
            
            div{
                display: block;
                margin-top: 10px;
            }
            span{
                font-size: 16px;               
                color: var(--color-dark);
                display: block;
            }
        }
        .btn{
            font-weight: 600;
            font-size: 16px;
            text-align: center;
            color: #181C22;
            padding: 15px;
            margin-top: 1em;
            border:1px solid #C3C9D3;

			&:hover{
				background: var(--color-peach);
				border-color: var(--color-peach);
				color: #fff;
			}
        }
    }

}

.delivery{
    --icon-width:220px;
    --form-width:300px;

	@include size(l){
		--icon-width:140px;
	}

	@include size(m){
		--form-width:100%;
	}

    .container{
        background: url(/dist/img/map2.svg) no-repeat center left;
        background-size: contain
    }

    &__box{
        display: flex;
        padding: 80px 0 200px;
		align-items: center;

		@include size(m){
			flex-wrap: wrap;
			padding-bottom: 80px;
			padding-top: 1em;
		}
        &-icon{
            width: var(--icon-width);
            height: 300px;

			@include size(m){
				width: var(--icon-width);
				height: var(--icon-width);
				margin: 0px auto 2em;
			}
			img{
				display: block;
				width: 100%;
				height: 100%;
			}

        }
        &-main{
            width: calc(100% - calc(var(--icon-width) + var(--form-width)));
            padding: 0 3em;
            box-sizing: border-box;

			@include size(m){
				width: 100%;
				padding: 0;
				text-align: center;
			}

            .h2{
                font-weight: bold;
                font-size: 36px;
				line-height: 1.2;
				@include size(l){
					font-size: 32px;
					font-weight: 600;
				}
            }
            .sub-h2{
                font-size: 22px;
            }
            p{
                font-weight: 300;
                font-size: 16px;
            }
        }
        &-form{
            width: var(--form-width);

			@include size(m){
				.form-state{
					width: 400px;
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					align-items: flex-start;
					margin: 0px auto;
					max-width: 100%;
					box-sizing: border-box;
				}
			}
        }
    }
}

.reviews{

    .container{
        position: relative;
    }

    .--width-l{
        box-sizing: border-box;
        padding-left: var(--side-padding);
        padding-right:  var(--side-padding);

		@include size(m){
			padding: var(--container-padding);
		}

    }


	@include size(m){
		.h2{
			text-align: center;
		}
	}

    &-slider{
        &.swiper-container-horizontal{
            .swiper-pagination{
                position: relative!important;
                bottom: 0;
                margin-top: 20px;

                &.swiper-pagination-bullets{
                    .swiper-pagination-bullet{
                        margin: 0 8px;
                        opacity: 1;
                        background: var(--color-gray);

                        &.swiper-pagination-bullet-active{
                            background: var(--color-dark);
                        }
                    }
                }
            }
        }

    }

    &__btn{
        position: absolute;
        bottom: calc(50% - 20px);
        width: 40px;
        height: 40px;
        cursor: pointer;

		@include size(m){
			z-index: 2;
			bottom: 0;
			width: 30px;
			height: 30px;
		}

        .icon{
            --size:100%;
            fill:var(--color-gray);
        }

        &.swiper-button-disabled{
            .icon{
                fill:var(--color-smoke);
            }
        }

        &-prev{
            left: 0;
			@include size(m){
				left: 1em;
			}
        }

        &-next{
            right: 0;
            transform: rotate(180deg);
			@include size(m){
				right: 1em;
			}
        }
    }

    &__item{
        background: var(--color-smoke);
        box-sizing: border-box;
        padding: 30px 50px;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        &-header{
            display: flex;
            margin-bottom: 20px;
        }
        &-image{
            width: 55px;
            height: 55px;
			border-radius: 50%;
			overflow: hidden;
			img{
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;

			}
        }
        &-content{
            width: calc(100% - 55px);
            box-sizing: border-box;
            padding-left: 10px;
        }   
        &-city{

            font-size: 13px;
            color: #838383;
        }
        &-name{
            
            font-size: 20px;
            color: #181C22;
            span{
                font-weight: 600;
            }
        }

        &-body{
            font-weight: 300;
            font-size: 18px;
            margin: 0;
            padding: 0;
        }

     
    }
}

.process-making{
	&__gallery{

		&.swiper-container-horizontal{
			.swiper-pagination{
				position: relative!important;
				bottom: 0;
				margin-top: 20px;

				&.swiper-pagination-bullets{
					.swiper-pagination-bullet{
						margin: 0 8px;
						opacity: 1;
						background: var(--color-gray);

						&.swiper-pagination-bullet-active{
							background: var(--color-dark);
						}
					}
				}
			}
		}

		&-item{
			display: flex;
			height: 100%;
			a{
				display: block;
				height: 220px;

				@include size(l){
					height: 170px;
				}

				img
				{
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}


.titles{
	@include size(s){
		margin-top: 1em;
	}


	.container{

	}
	.breadcrumbs {

		@include size(l){
			display: flex;
			font-size: 16px;
			font-weight: 300;
		}
	}
	h1, .h1{


		@include size(l){
			font-size: 33px;
		}

		@include size(s){
			font-size: 28px;
		}
	}
}

.product{
	background: url("/dist/img/city-bg.jpg") no-repeat center center;
	&__table{
		display: flex;
		--image-size:510px;
		@include size(l){
			--image-size:420px
		}
		@include size(s){
			flex-wrap: wrap;
		}
		&-left{
			width: var(--image-size);

			@include size(s){
				width: 100%;
			}
			img{
				display: block;
				width: 100%;
				max-height: 400px;
				object-fit: cover;
			}
		}
		&-right{
			width: calc(100% - var(--image-size));
			padding-left: 2em;
			@include size(s){
				width: 100%;
				padding-left: 0;
			}
			.titles{
				margin-top: 0;
				.container{
					padding: 0;
				}
				h1{
					line-height: 1em;

					@include size(l){
						font-size: 37px;
					}
				}
			}
		}
	}

	&__text{
		&-has-image{
		//	margin-bottom: 100px;
		}

		p{
			font-weight: 300;
			&.xl{
				font-weight: 500;
			}
		}
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			font-weight: 300;
			position: relative;
			&.--has-image{
				--offset:150px;
				padding-left: calc(var(--offset) + 1em);
				@include size(s){
					padding: 0;
				}
				&:before{
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					bottom: 0;
					width: var(--offset);
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					@include size(s){
						position: relative;
						width: var(--offset);
						height: var(--offset);
						position: relative;
						display: block;
						margin: 0 auto;
					}
				}

				&.--image-cross{
					&:before{
						background-image: url("/dist/img/cross.svg");
						margin-bottom: 2em;
					}
				}
				&.--image-dome{
					--offset:250px;
					padding-left: 0;
					padding-right: calc(var(--offset) + 1em);

					@include size(s){
						padding: 0;
					}
					&:before{
						left: auto;
						right: 0;
						height: var(--offset);
						background-image: url("/dist/img/dome.svg");
					}
				}
			}
			li{
				position: relative;
				margin-bottom: 10px;
				padding-left: 1em;
				&:before{
					content: '';
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: var(--color-dark);
					position: absolute;
					left: 0;
					top: calc(0.5em - 2px);
				}
			}
		}
	}

	.proofs{
		&__list{
			flex-wrap: wrap;
		}
		&__item{
			max-width: calc(calc(100% / 4) - 50px);

			max-width: 50%;
			width: 50%;
			margin-bottom: 50px;
			padding-right: 1em;
			box-sizing: border-box;

			@include size(m){
				max-width: 100%;
				width: 100%;
			}
			&:nth-child(3n),
			&:nth-child(4n)
			{
				margin-bottom: 0;
			}

			&-text{
				font-weight: 300;
				line-height: 1.4em;
				font-size: 16px;
			}
		}
	}

	.service{
		background:transparent;
		margin-bottom: 2em;
		&__plus{
			padding-bottom: 1em;
		}
	}

	&__examples
	{

		background: #fff;

	}
	&__example{

		height: 100%;
		&-title{
			display: block;
			width: 100%;
			margin-bottom: 10px;
			font-size: 18px;
			text-align: center;
			font-weight: 400;
		}
		&-image{
			display: block;
			width: 100%;
			height: 100%;
			img{
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
			}

		}
	}
}

.gallery{
	&__list{

	}
	&__item{
		width: 100%;
		height: 200px;
		border-radius: 5px;
		overflow: hidden;
		transition: 200ms;
		-webkit-filter: brightness(1.1) contrast(1.1);
		filter: brightness(1.1) contrast(1.1);
		box-shadow: 0px 0px 0px 1px #000;
		&:hover{
			opacity: 0.8;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@include size(s){
			height: 140px;
		}
	}
}

.about-company{
	p{
		font-weight: 300;
	}
}

.--is-left-menu{
	.nav-popup{
		display: block;
	}
}
.nav-popup{
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 100;

	&__left{
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 300px;
		background: whitesmoke;
		z-index: 2;
		box-sizing: border-box;
		overflow: auto;


	}
	&__links{
		padding: 25px 20px;
		display: block;
		background: #fff;
		a{
			display: block;
			font-size: 18px;
			font-weight: 400;
			margin-bottom: 14px;
		}
	}

	&__contacts{
		padding: 1em;
		display: block;
		.contacts__padding{
			padding-left: 30px;
		}

		.contacts__value{
			a{
				display: block;
			}
			b {
				font-size: 18px;
				font-weight: 400;
			}
		}


	}

	&__bg{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #00000080;
		z-index: 1;
	}

	&__close{
		position: absolute;
		top: 25px;
		right: 1em;
		background: #000;
		z-index: 3;
		width: 30px;
		height: 30px;


		background: url(/img/close.svg) no-repeat center;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg height='329pt' viewBox='0 0 329.26933 329' fill='white' width='329pt' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0'/%3E%3C/svg%3E");

		background-size: contain;
	}
}

.popup__thanks{
	.v-popup__dialog{
		max-width: 400px;
		padding: 2em;
	}
}
.thanks{
	text-align: center;
	&-img{
		display: block;
		--size: 100px;
		margin: 0 auto 1em;
	}
	&-title{
		display: block;
		font-size: 22px;
		display: block;
		margin-bottom: 10px;
	}
	&-subtitle{
		display: block;
		font-weight: 300;
	}
}

.creating{
	&__list{

	}
	&__item{
		display: flex;
		box-sizing: border-box;
		padding: 1em;
		background: #F9F7F2;
		width: 100%;
		height: 100%;
		align-items: center;
		@include size(xs){
			flex-wrap: wrap;
		}
		&-img{
			width: 45%;
			object-fit: contain;
			box-sizing: border-box;
			@include size(m){
				width: 35%;
			}
			@include size(xs){
				width: 50%;
				margin: 0 auto;
			}
		}
		&-content{
			width: 55%;
			padding-left: 10px;
			box-sizing: border-box;
			@include size(m){
				width: 65%;
			}
			@include size(xs){
				padding: 0;
				width: 100%;
				text-align: center;
			}
			.btn{
				padding: 12px 22px;
				font-size: 16px;
			}
		}
		&-title{

			font-weight: 600;
			font-size: 27px;
			line-height: 27px;
			color: #181C22;
			margin-bottom: 10px;

			@include size(m){
				font-size: 20px;
			}
			@include size(s){
				font-size: 24px;
			}
			@include size(xs){
				font-size: 19px;
			}
		}
		&-count{

			font-weight: 300;
			font-size: 18px;
			line-height: 27px;
			color: #181C22;
			margin-bottom:15px;
			@include size(m){
				font-size: 16px;
			}

			@include size(s){
				font-size: 18px;
			}
			@include size(xs){
				font-size: 16px;
			}

		}

	}
	.grid-item:nth-child(2){
		.creating__item{
			background: #F7F7F9;
		}
	}
}

.call-back-modal{
	@include size(s){
		right: auto;
		bottom: 2em!important;
		left: 2em;
	}
}
.v-form{
	width:  100%;
	max-width: 400px;
	&__title{
		font-weight: 600;
		line-height: 1em;
		margin-bottom: 10px;
	}
	&__small{
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 22px;
		color: #838383;
		margin-bottom: 15px;
	}
	&__text{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.2em;
		margin-bottom: 10px;
	}

	&__assept{
		line-height: 1.2em;
		font-size: 13px;
		text-align: center;
		font-weight: 300;
		margin-top: 12px;
		color: #777777;
	}

	&__box{
		display: block;
		margin-bottom: 5px;
		&-input{
			font-size: 1em;
			padding: 15px 20px;
			border: 1px solid #E6E7E9;
			width: 100%;
			box-sizing: border-box;
			border-radius: 4px;
			margin-bottom: 10px;
			font-weight: 300;
		}

		&-small{
			font-size: 11px;
			color: #838383;
			line-height: 1.5em;
			display: block;
			padding: 0 2em;
			box-sizing: border-box;
			margin-bottom: 30px;
		}
	}


}